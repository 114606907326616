import type { Thunk } from '@/bootstrap/thunks';
import type { ChainOptions } from '../../../../neosActions';
import { DefaultingScope } from '../../../../neosModel';
import { DefaultingOptions } from '../../../../rfq/rfqActions';

export function createDefaultForwardStartDateRequested(
  rfqId: string,
  chainOptions: ChainOptions | undefined,
): Thunk {
  return function defaultRfqExtraFeatureRequested(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const overrideScopes = [
      DefaultingScope.DETERMINATION_METHOD,
      DefaultingScope.UNDERLYING,
      DefaultingScope.EXPECTED_N,
      DefaultingScope.FORWARD_START_DETERMINATION_METHOD,
      DefaultingScope.BOOKING_INFO,
    ];

    const retryOnErrorOptions = {
      retries: 1,
    };
    const defaultingOptions = new DefaultingOptions({
      overrideScopes,
      enrichScopes: [DefaultingScope.SALES_DIFFUSION, DefaultingScope.TRADE_DATE],
    });

    dispatch(
      createDefaultRfqRequestedAction(rfqId, defaultingOptions, chainOptions, retryOnErrorOptions),
    );
  };
}
