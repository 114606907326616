import { useAppSelector } from '@/bootstrap/hooks';
import { selectors } from '@/bootstrap/selectors';
import type { ElsBasket } from '@/neos/business/rfq/strategy/leg/product/productModel';
import { createUpdateAllBasketSpotConfirmedThunk } from '@/neos/business/rfq/strategy/reference/updateAllBasketSpotConfirmedThunk';
import { Switch } from '@/neos/components/share/switch/Switch';
import { useDispatch } from 'react-redux';

interface BasketSpotConfirmedCheckboxProps {
  rfqId: string;
  product: ElsBasket;
}

export function BasketSpotConfirmedCheckbox({ product, rfqId }: BasketSpotConfirmedCheckboxProps) {
  const dispatch = useDispatch();

  const references = useAppSelector(state =>
    selectors.selectElsBasketReferences(state, rfqId, product),
  );

  const isDisabled = references.length === 0;
  const isChecked =
    references.length > 0 && references.every(reference => reference?.isSpotConfirmed);

  const onCheckboxChange = () => {
    if (isDisabled) {
      return;
    }
    dispatch(createUpdateAllBasketSpotConfirmedThunk(rfqId, product, !isChecked));
  };

  return (
    <Switch
      disabled={isDisabled}
      data-e2e="neos-extra-features-els-equitybloc-spotConfirmed"
      checked={isChecked}
      onChange={() => onCheckboxChange()}
      labelName="Price Confirmed"
    />
  );
}
