import type { Thunk } from '@/bootstrap/thunks';
import type { OnyxRfq } from '@/neos/business/rfq/rfqOnyxModel';
import { DefaultingScope } from '../models';
import type { DefaultingOptions, StrategiesFilterer } from '../rfqActions';

export function createDefaultRfqThunk(
  rfq: OnyxRfq,
  { allScopes }: DefaultingOptions,
  strategiesFilterer: StrategiesFilterer,
): Thunk {
  return function defaultRfqThunk(
    dispatch,
    getState,
    {
      selectors: { getRfqHasFairPrices },
      thunks: {
        neos: { createLoadPartialRfqThunk, createRequestRfqMissingUnderlyingsThunk },
        createWarningToasterThunk,
      },
    },
  ) {
    const state = getState();

    dispatch(
      createLoadPartialRfqThunk(rfq, strategiesFilterer),
      createRequestRfqMissingUnderlyingsThunk(rfq.uuid),
    );

    const areFairPricesRequested = allScopes.includes(DefaultingScope.AGGREGATED_FAIR_PRICES);

    if (areFairPricesRequested) {
      const rfqHasFairPrices = getRfqHasFairPrices(state, rfq.uuid);
      if (!rfqHasFairPrices) {
        dispatch(
          createWarningToasterThunk({
            message: 'Some or all fair price computations are not available!',
          }),
        );
      }
    }
  };
}
