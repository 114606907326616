import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import type { OnyxCurrentUserPreferences } from '@/neos/business/currentUserPreferences/currentUserPreferencesMappers';
import type { UserPreferencesStrategyTraderGroup } from '@/neos/business/currentUserPreferences/currentUserPreferencesModel.ts';
import { isDefined } from '@/util/undefinedAndNull/isDefined.ts';

export const currentUserPreferencesThunks = {
  createIntegrateCurrentUserPreferencesThunk,
  createUpdateUserPreferencesCityThunk,
  createUpdateUserPreferencesCountryThunk,
  createUpdateAndPostStrategyTraderGroupsThunk,
  createUpdateAndPostUserPreferencesThunk,
};

export function createIntegrateCurrentUserPreferencesThunk(
  onyxCurrentUserPreferences: OnyxCurrentUserPreferences,
): Thunk {
  return function integrateCurrentUserPreferencesThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { currentUserPreferencesActionCreators },
      },
      fromMappers: { mapFromOnyxCurrentUserPreferences },
    },
  ) {
    const currentUserPreferences = mapFromOnyxCurrentUserPreferences(onyxCurrentUserPreferences);
    dispatch(currentUserPreferencesActionCreators.currentUserPrefReceived(currentUserPreferences));
  };
}

export function createUpdateUserPreferencesCityThunk(cityId: number): Thunk {
  return function updateUserPreferencesCityThunk(
    dispatch,
    getState,
    {
      selectors: { currentUserPreferencesSelectors },
      actionCreators: {
        neos: { currentUserPreferencesActionCreators },
      },
    },
  ) {
    const state = getState();
    const selectedCity = currentUserPreferencesSelectors.selectCityById(state, cityId);

    if (selectedCity) {
      dispatch(
        createUpdateAndPostUserPreferencesThunk([
          currentUserPreferencesActionCreators.updateCurrentPreferencesByField({
            field: 'city',
            newValue: selectedCity,
          }),
        ]),
      );
    }
  };
}

export function createUpdateUserPreferencesCountryThunk(countryId: number): Thunk {
  return function updateUserPreferencesCountryThunk(
    dispatch,
    getState,
    {
      selectors: { currentUserPreferencesSelectors },
      actionCreators: {
        neos: { currentUserPreferencesActionCreators },
      },
    },
  ) {
    const state = getState();
    const selectedCountry = currentUserPreferencesSelectors.selectCountryById(state, countryId);

    if (selectedCountry) {
      dispatch(
        createUpdateAndPostUserPreferencesThunk([
          currentUserPreferencesActionCreators.updateCurrentPreferencesByField({
            field: 'country',
            newValue: selectedCountry,
          }),
        ]),
      );
    }
  };
}

export function createUpdateAndPostStrategyTraderGroupsThunk(
  data: UserPreferencesStrategyTraderGroup[],
): Thunk {
  return function updateUserPreferencesStrategyTraderGroupThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { currentUserPreferencesActionCreators },
      },
    },
  ) {
    dispatch(
      createUpdateAndPostUserPreferencesThunk([
        currentUserPreferencesActionCreators.updateCurrentPreferencesByField({
          field: 'strategyTraderGroup',
          newValue: data.filter(row => isDefined(row.traderGroupId)),
        }),
      ]),
    );
  };
}

export function createUpdateAndPostUserPreferencesThunk(dispatchable: Dispatchable[]): Thunk {
  return function updateUserPreferencesThunk(
    dispatch,
    getState,
    {
      selectors: { currentUserPreferencesSelectors },
      actionCreators: {
        neos: { currentUserPreferencesActionCreators },
      },
    },
  ) {
    dispatch(dispatchable);

    const state = getState();

    dispatch(
      currentUserPreferencesActionCreators.createPostCurrentUserPreferencesAction(
        currentUserPreferencesSelectors.selectCurrentUserPreferences(state),
      ),
    );
  };
}
