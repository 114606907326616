import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import { hasMaturity } from '../../../../../../../../business/neosModel';
import { isLegMaturityUpToDate } from '../../../maturityCells/modelGetters/getMaturitiesFieldsArray';
import type { BaseSecondaryTypeField, NotApplicableField } from '../../secondaryTypeModel';
import type { LegSecondaryTypeWidgetOwnProps } from './LegSecondaryTypeWidget';

export interface SecondaryWidgetModel extends BaseSecondaryTypeField {
  isLegMaturityValid: boolean;
  isElsBasket: boolean;
}

export type SecondaryTypeFieldModel = SecondaryWidgetModel | NotApplicableField;

export interface LegSecondaryTypeWidgetModel {
  legsSecondaryTypes: SecondaryTypeFieldModel[];
  isLoading: boolean;
  isReadonly: boolean;
}

export function getLegSecondaryTypeWidgetModel(
  appState: AppState,
  { rfqId, strategyId }: LegSecondaryTypeWidgetOwnProps,
  selectors: Selectors,
): LegSecondaryTypeWidgetModel {
  const hasACompositionLeg = selectors.hasACompositionLeg(appState, strategyId, selectors);
  const isLoading = selectors.isRfqLoading(appState.ui, rfqId);
  const isReadOnlyAtCurrentWorkflow = selectors.isReadOnlyAtCurrentWorkflow(
    appState,
    strategyId,
    selectors,
  );
  if (hasACompositionLeg) {
    return {
      legsSecondaryTypes: [],
      isLoading,
      isReadonly: isReadOnlyAtCurrentWorkflow,
    };
  }

  const { legIds } = selectors.getStrategyData(appState, strategyId);

  const legsSecondaryTypes = legIds.map((legId): SecondaryTypeFieldModel => {
    const isEligible = selectors.isLegEligibleToSecondaryEventCreation(
      appState,
      rfqId,
      legId,
      selectors,
    );

    if (!isEligible) {
      return { isEligible, legId };
    }

    const { productId } = selectors.getLegData(appState, legId);
    const product = selectors.getProduct(appState, productId);
    const isElsBasket = selectors.isRfqElsBasket(appState, rfqId, selectors);
    const secondaryType = selectors.getLegSecondaryEventCreation(appState, {
      rfqId,
      legId,
    });

    const isLegMaturityValid = hasMaturity(product) && isLegMaturityUpToDate(product.maturity);

    return {
      legId,
      isEligible: true,
      secondaryType,
      isLegMaturityValid,
      isElsBasket,
    };
  });

  return {
    legsSecondaryTypes,
    isLoading,
    isReadonly: isReadOnlyAtCurrentWorkflow,
  };
}
