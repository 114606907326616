import type { Thunk } from '@/bootstrap/thunks';
import { uniq } from 'lodash';
import type { ChainOptions } from '../neosActions';

export function createRequestRfqMissingUnderlyingsThunk(
  rfqId: string,
  chainOptions?: ChainOptions,
  otherUnderlyingIdsToRetrieve: string[] = [],
): Thunk {
  return function requestMissingRfqUnderlyingsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction, createPartialUnderlyingInfoRequestedAction },
      },
    },
  ) {
    const appState = getState();

    const isElsBasketRfq = selectors.isRfqElsBasket(appState, rfqId, selectors);

    const underlyingsIdsWithoutAnyInfo = selectors.getUnderlyingsIdsWithoutAnyInfo(
      appState,
      rfqId,
      selectors,
    );

    const underlyingsIdsWithoutFullInfo = selectors.getUnderlyingsIdsWithoutFullInfo(
      appState,
      rfqId,
      selectors,
    );
    const underlyingsIdsWithMissingUdlInfo = isElsBasketRfq
      ? underlyingsIdsWithoutAnyInfo
      : underlyingsIdsWithoutFullInfo;

    const allMissingUnderlyingIds = underlyingsIdsWithMissingUdlInfo.concat(
      otherUnderlyingIdsToRetrieve,
    );

    const uniqMissingUnderlyingIds = uniq(allMissingUnderlyingIds);

    if (isElsBasketRfq && underlyingsIdsWithoutAnyInfo.length > 0) {
      dispatch(
        createPartialUnderlyingInfoRequestedAction([rfqId], uniqMissingUnderlyingIds, chainOptions),
      );
    } else if (!isElsBasketRfq && underlyingsIdsWithoutFullInfo.length > 0) {
      dispatch(
        createUnderlyingInfoRequestedAction([rfqId], uniqMissingUnderlyingIds, chainOptions),
      );
    } else {
      const dispatchables = chainOptions?.success?.dispatchables ?? [];
      dispatchables.forEach(toBeDispatched => dispatch(toBeDispatched));
    }
  };
}
