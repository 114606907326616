import type { Thunk } from '@/bootstrap/thunks';
import type { ActionContainer } from '@/util/actionContainer';
import type { NeosActionCreators } from '../../neosActionCreators';
import type { RetryOnErrorOptions } from '../../neosActions';
import { DefaultingScope } from '../../neosModel';
import { DefaultingOptions } from '../rfqActions';

type TenorSource = 'MATURITY' | 'FORWARD_START_DATE' | 'STRIKE_DATE';

interface ExtraArguments {
  tenorSource: 'FORWARD_START_DATE' | 'STRIKE_DATE';
  tenor: string | undefined;
  productId: string;
  sourceId: string;
}

export function createRfqDefaultTenorMaturityThunk(
  rfqId: string,
  strategyId: string,
  idToUpdate: string,
  tenor: string | undefined,
  tenorSource: TenorSource,
): Thunk {
  return function rfqDefaultTenorMaturityThunk(
    dispatch,
    getState,
    {
      selectors,
      thunks: {
        neos: {
          createUpdateLegMaturityTenorThunk,
          changeElsIsScheduleObsoleteThunk,
          changeClsIsScheduleObsoleteThunk,
        },
      },
      actionCreators,
    },
  ) {
    const state = getState();
    const {
      neos: { createDefaultRfqRequestedAction },
    } = actionCreators;
    const { legIds } = selectors.getStrategyData(state, strategyId);

    const retryOnErrorOptions: RetryOnErrorOptions = {
      retries: 1,
    };
    const overrideScopes = [
      DefaultingScope.DETERMINATION_METHOD,
      DefaultingScope.UNDERLYING,
      DefaultingScope.EXPECTED_N,
      DefaultingScope.TENOR_DATE,
      DefaultingScope.END_OF_OBSERVATION,
    ];

    const isElsStrategy = selectors.isElsStrategy(state, strategyId, selectors);

    const isClsStrategy = selectors.isClsStrategy(state, strategyId, selectors);

    if (isClsStrategy) {
      overrideScopes.push(
        DefaultingScope.CALCULATION_METHOD,
        DefaultingScope.RATE_OVERNIGHT,
        DefaultingScope.RATE_TENOR,
        DefaultingScope.FIXED_DAY_TYPE,
      );
    }

    if (tenorSource === 'STRIKE_DATE') {
      overrideScopes.push(DefaultingScope.MATURITY_DATE);
    }

    if (isElsStrategy && (tenorSource === 'MATURITY' || tenorSource === 'STRIKE_DATE')) {
      overrideScopes.push(DefaultingScope.FIXED_DAY_TYPE, DefaultingScope.BREAK_FEE);
    }

    if (tenorSource === 'MATURITY') {
      overrideScopes.push(DefaultingScope.LIMIT_WINDOW);
    }

    const defaultingOptions = new DefaultingOptions({
      overrideScopes,
      enrichScopes: [DefaultingScope.SALES_DIFFUSION, DefaultingScope.TRADE_DATE],
      isSettlementOtcOverride: true,
    });

    const actions: ActionContainer[] = [];
    if (tenorSource === 'MATURITY') {
      const { productId } = selectors.getLegData(state, idToUpdate);
      dispatch(changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
      dispatch(changeClsIsScheduleObsoleteThunk(rfqId, productId, true));

      dispatch(createUpdateLegMaturityTenorThunk(rfqId, strategyId, idToUpdate, tenor));
    } else {
      legIds.forEach(legId => {
        const { productId } = selectors.getLegData(state, legId);
        dispatch(changeElsIsScheduleObsoleteThunk(rfqId, productId, true));
        dispatch(changeClsIsScheduleObsoleteThunk(rfqId, productId, true));

        actions.push(
          buildUpdateTenorState(actionCreators.neos, {
            tenorSource,
            tenor,
            productId,
            sourceId: idToUpdate,
          }),
        );
      });
      dispatch(actions);
    }

    dispatch(
      createDefaultRfqRequestedAction(rfqId, defaultingOptions, undefined, retryOnErrorOptions),
    );
  };
}

function buildUpdateTenorState(actionCreators: NeosActionCreators, args: ExtraArguments) {
  switch (args.tenorSource) {
    case 'FORWARD_START_DATE':
      return actionCreators.featureCrudActions.patchOrInsert(
        { strategyId: args.sourceId, type: 'FORWARD_START' },
        {
          type: 'FORWARD_START',
          strategyId: args.sourceId,
          forwardStartTenor: args.tenor,
          forwardStartDate: undefined,
        },
      );
    case 'STRIKE_DATE':
      return actionCreators.productCrudActions.update(args.productId, {
        strikeTenor: args.tenor,
        strikeDate: undefined,
      });
  }
}
