import type {
  StockLoanType,
  TradingBusinessType,
} from '@/neos/business/currentUserPreferences/currentUserPreferencesModel';
import type { CalculationMethodType } from '@/neos/components/rfq/strategies/strategy/features/elsFeatures/RateBloc/CalculationMethodModel';
import type {
  ElsType,
  OnyxProduct,
  OnyxProductUnderlying,
  PriceWithUnit,
} from '../../../../../../neos/business/neosOnyxModel';
import type { AccrualType } from './productModel';
import type { Way } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import type { Counterpart } from '@/neos/business/rfq/actorsSetup/counterpart/counterpartModel.ts';

export const EBreakFeeElection = {
  NONE: 'None',
  FLAT_FEE: 'Flat Fee',
  AMORTIZED_FEE: 'Amortized Fee',
  FUNDING_FEE: 'Funding Fee',
  FLAT_AND_FUNDING_FEE: 'Flat Fee & Funding Fee',
  AMORTIZED_AND_FUNDING_FEE: 'Amortized Fee & Funding Fee',
} as const;

export const brokenPeriodPositions = {
  NONE: 'None',
  FIRST_PERIOD: 'First period',
  LAST_PERIOD: 'Last period',
  FIRST_AND_LAST_PERIODS: 'First and last periods',
} as const;

export const EBreakFeePeriodTypes = {
  NONE: 'None',
  INCREASE: 'Increase',
  PARTIAL_CLOSE: 'Partial close',
  FULL_CLOSE: 'Full close',
} as const;

export const basisTypes = {
  BASIS_30_360: '30/360',
  BASIS_30E_365: '30E/365',
  BASIS_ACT_360: 'ACT/360',
  BASIS_ACT_365: 'ACT/365',
  BASIS_ACT_ACT: 'ACT/ACT',
} as const;

export const valuationTypes = {
  FAIR_VALUE: 'Fair value (MTM)',
  ACCRUED: 'Accrued',
} as const;

export const terminationRightTypes = {
  NONE: 'None',
  UNILATERAL_CLIENT: 'Unilateral Client',
  UNILATERAL_SG: 'Unilateral SG',
  BILATERAL: 'Bilateral',
} as const;

export const terminationConditionTypes = {
  AT_RESET_DATE: 'At reset date',
  AT_ANY_TIME: 'At any time',
} as const;

export const dealTypes = {
  ONE_DELTA: 'One Delta',
  EQUITY_FINANCE: 'Equity Finance',
} as const;

export const equityFinanceTypes = {
  DIV: 'Div',
  FUNDING: 'Funding',
  LONG_TERM_DIV: 'LongTerm Div',
  EXCLUSIVE: 'Exclusive',
  OPEN: 'Open',
  TERM: 'Term',
  GUARANTEED: 'Guaranteed',
} as const;

export type BrokenPeriodPosition = keyof typeof brokenPeriodPositions;

export type BasisType = keyof typeof basisTypes;

export type TerminationConditionType = keyof typeof terminationConditionTypes;

export type EBreakFeeElectionType = keyof typeof EBreakFeeElection;

export type TerminationRightType = keyof typeof terminationRightTypes;

export type ValuationType = keyof typeof valuationTypes;

export type BreakFeePeriodType = keyof typeof EBreakFeePeriodTypes;

export type DealType = keyof typeof dealTypes;

export type EquityFinanceType = keyof typeof equityFinanceTypes;

export interface OnyxBreakFeePeriod {
  breakFeePeriodType: BreakFeePeriodType;
  fee: number | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  nominalMin: PriceWithUnit | undefined;
  nominalMax: PriceWithUnit | undefined;
}

export interface BreakFeePeriod {
  id: string;
  type: BreakFeePeriodType;
  fee: number | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  nominalMin: number | undefined;
  nominalMax: number | undefined;
}

export interface OnyxStockLoanComponent {
  uuid: string | undefined | null;
  allIn: PriceWithUnit | undefined | null;
  borrowPortfolio: string | undefined | null;
  lendPortfolio: string | undefined | null;
  dividendRequirement: PriceWithUnit | undefined | null;
  fees: PriceWithUnit | undefined | null;
  quantity: number | undefined | null;
  underlying: OnyxProductUnderlying | undefined | null;
}

export type OnyxStockLoanHedge = {
  maturity: string | undefined | null;
  portfolio: string | undefined | null;
  stockLoanComponents: OnyxStockLoanComponent[] | undefined | null;
  stockLoanType: StockLoanType | undefined | null;
  tradingBusiness: TradingBusinessType | undefined | null;
  bookingId: string | undefined | null;
  bookingApplication: 'XONE' | undefined | null;
};

export const internalEquityHedgeTypes = {
  INTERNAL: 'Internal',
} as const;

export type InternalEquityHedgeType = keyof typeof internalEquityHedgeTypes;

export const otherEquityHedgeTypes = {
  BACK_TO_BACK: 'Back to back',
  WITH_BROKER: 'With broker',
} as const;

export type OtherEquityHedgeType = keyof typeof otherEquityHedgeTypes;

export type EquityHedgeType = OtherEquityHedgeType | InternalEquityHedgeType;

export interface OnyxEquityHedgeComponent {
  counterparty: Counterpart | undefined;
  uuid: string | undefined | null;
  underlying: OnyxProductUnderlying | undefined | null;
  bookingId: string | undefined | null;
  bookingApplication: 'XONE' | undefined | null;
  quantity: number | undefined | null;
  spot: PriceWithUnit | undefined;
  spotNet: PriceWithUnit | undefined;
  way: Way | undefined;
  nominal: PriceWithUnit | undefined | null;
  broker: string | undefined | null;
  portfolio: string | undefined | null;
  internalPortfolio: string | undefined | null;
  equityType: EquityHedgeType | undefined | null;
}

export type OnyxEquityHedge = {
  broker: string | undefined | null;
  equityType: EquityHedgeType | undefined | null;
  currency: string | undefined | null;
  way: Way | undefined | null;
  bookedManually: boolean | undefined | null;
  counterparty: Counterpart | undefined | null;
  equityHedgeComponents: OnyxEquityHedgeComponent[] | undefined | null;
};

export interface OnyxProductEls {
  observationShift?: number;
  lookbackPeriod?: number;
  paymentDelay?: number;
  lockout?: number;
  accrual?: AccrualType;
  elsType?: ElsType;
  calculationMethod?: CalculationMethodType;
  generateFrom?: GenerateFromType;
  strikeDate?: string;
  equityResetType?: ResetType;
  rateReset?: ResetType;
  resetMode?: ResetMode;
  brokenPeriod?: BrokenPeriodType;
  fixedDay?: FixedDayType;
  conventionDay?: BusinessDayConvention;
  effectiveDate?: string;
  effectiveDateOffset?: number;
  rateSchedulePeriods?: RateLegPeriodDates[];
  equitySchedulePeriods?: EquityLegPeriodDates[];
  roleDefinition?: RoleDefinition;
  calculationAgent?: CalculationParty;
  determiningParty?: CalculationParty;
  hedgingParty?: HedgingParty;
  rateSpreadAdjustment?: SpreadAdjustmentType;
  dividendSpreadAdjustment?: SpreadAdjustmentType;
  dividendPriceType?: DividendPriceType;
  linearInterpolation?: LinearInterpolation;
  lookthroughDR?: LookthroughDR;
  rightToSubstituteScope?: RightToSubstituteScope;
  rightToSubstituteConditions?: RightToSubstitute;
  relatedExchange?: string;
  termNotice?: number;
  clientTermNotice?: number;
  dailyMinSize?: PriceWithUnit;
  dailyMaxSize?: PriceWithUnit;
  secondaryMarketAllowed?: boolean;
  componentSecurityIndexAnnex?: boolean;
  breakFeeElection?: EBreakFeeElectionType;
  breakFeePeriods?: Array<OnyxBreakFeePeriod>;
  terminationType?: ValuationType;
  valuationType?: ValuationType;
  terminationRights?: TerminationRightType;
  terminationConditions?: TerminationConditionType;
  basisType?: BasisType;
  compoundRate?: boolean;
  settlementMethodElection?: boolean;
  electionFee?: number;
  electionDate?: string;
  dealType?: DealType;
  localTaxes?: boolean;
  specialDividends?: boolean;
  declaredCashDiv?: { value: number; unit: '%' };
  rateFixingOffset?: number;
  brokenPeriodPosition?: BrokenPeriodPosition;
  derogateRateFixingOffset?: boolean;
  isScheduleObsolete?: boolean;
  hedgeComment?: string | null;
  stockLoanHedge?: OnyxStockLoanHedge | null;
  equityHedge?: OnyxEquityHedge | null;
  equityPreconfComment?: string | null;
  ratePreconfComment?: string | null;
  terminationPreconfComment?: string | null;
}

export interface PeriodDates {
  startDate: string;
  endDate: string;
  paymentDate: string;
}

export type EquityLegPeriodDates = PeriodDates;
export type DividendLegPeriodDates = PeriodDates & {
  theoreticalPeriodPaymentDate: string;
};
export type DividendPeriod = LegPeriod<DividendLegPeriodDates>;

export type RatePeriodDates = PeriodDates & {
  fixingDate: string;
};
export type RateOverNightPeriodDates = RatePeriodDates & {
  firstFixingDate: string;
};
export type RateLegPeriodDates = RatePeriodDates | RateOverNightPeriodDates;

export type LegPeriodDates = EquityLegPeriodDates | DividendLegPeriodDates | RateLegPeriodDates;

export interface LegPeriod<T extends LegPeriodDates> {
  dates: T;
  uuid: string;
}

export type TerminationCondition = 'AT_RESET_DATE' | 'AT_ANY_TIME';

export type RoleDefinition = 'AS_PER_MCA' | 'SPECIFIC' | 'NOT_AVAILABLE';

export type HedgingParty = 'SG' | 'COUNTERPART' | 'BOTH';

export type CalculationParty =
  | HedgingParty
  | 'SG_WITH_CLIENT_DISPUTE'
  | 'SG_WITH_CLIENT_DISPUTE_EVENT_DEFAULT';

export type SpreadAdjustmentType = TerminationCondition | 'NONE';

export type LinearInterpolation =
  | 'AS_SPECIFIED_IN_MASTER_CONFIRMATION'
  | 'NOT_APPLICABLE'
  | 'INITIAL'
  | 'INITIAL_AND_FINAL'
  | 'FINAL'
  | 'ANY_PERIOD'
  | 'APPLICABLE';

export type LookthroughDR = 'FULL' | 'PARTIAL';

export type BusinessDayConvention =
  | 'NONE'
  | 'PRECEDING'
  | 'FOLLOWING'
  | 'MODIFIED_FOLLOWING'
  | 'CLOSED';

export type ResetType =
  | 'NONE'
  | 'DAILY'
  | 'WEEKLY'
  | 'TWO_WEEKS'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'YEARLY'
  | 'TWO_MONTHS';

export type ResetMode = 'NONE' | 'NOTIONAL' | 'QUANTITY';

export type GenerateFromType =
  | 'STRIKE_DATE'
  | 'EFFECTIVE_DATE'
  | 'FINAL_VALUATION_DATE'
  | 'FINAL_SETTLEMENT_DATE';

export type RightToSubstitute =
  | 'NONE'
  | 'AT_ANY_TIME'
  | 'AT_RESET_DATES'
  | 'IN_CASE_OF_DIV_OR_CORP_ACTION'
  | 'IN_CASE_OF_CORP_ACTION';

export type RightToSubstituteScope =
  | 'NONE'
  | 'CUSTOM'
  | 'EQUIVALENT_ASSET_QUALITY'
  | 'FTSE100_FINANCING'
  | 'MUTUAL_AGREEMENT';

export type FixedDayType =
  | 'MON1'
  | 'TUE1'
  | 'WED1'
  | 'THU1'
  | 'FRI1'
  | 'MON2'
  | 'TUE2'
  | 'WED2'
  | 'THU2'
  | 'FRI2'
  | 'MON3'
  | 'TUE3'
  | 'WED3'
  | 'THU3'
  | 'FRI3'
  | 'MON4'
  | 'TUE4'
  | 'WED4'
  | 'THU4'
  | 'FRI4'
  | 'LAST'
  | 'DAY1'
  | 'DAY2'
  | 'DAY3'
  | 'DAY4'
  | 'DAY5'
  | 'DAY6'
  | 'DAY7'
  | 'DAY8'
  | 'DAY9'
  | 'DAY10'
  | 'DAY11'
  | 'DAY12'
  | 'DAY13'
  | 'DAY14'
  | 'DAY15'
  | 'DAY16'
  | 'DAY17'
  | 'DAY18'
  | 'DAY19'
  | 'DAY20'
  | 'DAY21'
  | 'DAY22'
  | 'DAY23'
  | 'DAY24'
  | 'DAY25'
  | 'DAY26'
  | 'DAY27'
  | 'DAY28'
  | 'DAY29'
  | 'DAY30';

export type BrokenPeriodType = 'LONG_LONG' | 'SHORT_SHORT' | 'SHORT_LONG' | 'LONG_SHORT';

export type DividendPaymentDate =
  | 'MATURITY_WITH_COMPOUND_RATE'
  | 'DIVIDEND_PAYMENT_DATE'
  | 'NEXT_RESET_PAYMENT_DATE'
  | 'MATURITY'
  | 'EACH_EXECUTION_DATE';

export type DividendPriceType = 'EF_DIVIDEND_GUARANTEED_AND_REPO' | 'OPTION_DIVIDEND_OPEN_REPO';

export function isElsOnyxProduct(
  product: OnyxProduct,
): product is Extract<OnyxProduct, OnyxProductEls> {
  return (
    product.discriminator === 'ELS' ||
    product.discriminator === 'PRS' ||
    product.discriminator === 'TRS'
  );
}
