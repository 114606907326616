import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { isElsBasketProduct, isElsProduct } from '../legModel';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { neosThunks } from '@/neos/business/thunks';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';

export function createSwapCurrencyChangeThunk(
  rfqId: string,
  strategyId: string,
  productId: string,
  swapCurrency: string,
): Thunk {
  return function swapCurrencyChangeThunk(dispatch, getState, { selectors, actionCreators }) {
    const state = getState();
    const isInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    const overrideScopes = [
      DefaultingScope.CALCULATION_METHOD,
      DefaultingScope.BREAK_FEE,
      DefaultingScope.DIVIDEND_CURRENCY,
      DefaultingScope.INTEREST_RATE_INDEX,
      DefaultingScope.UNITS,
    ];

    if (isInitiatedByTrader) {
      overrideScopes.push(DefaultingScope.BROKER_COMMISSION_CURRENCY);
    }
    if (isElsBasketProduct(product)) {
      overrideScopes.push(
        DefaultingScope.UNDERLYING,
        DefaultingScope.NOTIONAL,
        DefaultingScope.NEGOTIATED_SIZE,
      );
    }

    const dispatchables: Array<Dispatchable> = [
      neosThunks.createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        productId,
        'swapCurrency',
        swapCurrency,
      ),
    ];

    if (isElsProduct(product)) {
      dispatchables.push(
        actionCreators.neos.createCurvesListRequestedAction(swapCurrency, rfqId, 'ELS'),
      );
      const options = new DefaultingOptions({
        overrideScopes,
        enrichScopes: [DefaultingScope.FOREX],
        isSettlementOtcOverride: true,
      });

      dispatchables.push(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    } else {
      dispatchables.push(neosThunks.createFetchCurvesListBasedOnCurrencyThunk(rfqId, [strategyId]));
    }

    dispatch(dispatchables);
  };
}
