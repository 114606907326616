/** this file is an entry point so it should remain as light as possible avoid using dependencies as much as you can.
 * see : index.html
 * see : initTheme.ts
 */
export type Theme = 'dark' | 'standard';

const sgbsVersion = import.meta.env.VITE_SGBS_VERSION;
const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${sgbsVersion}`;

export function initSgBootstrap() {
  const theme = getTheme();

  const widgetUrls = {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${theme}.min.css`, // SG Boostrap 5 url
    aggrid: `${cdnUrl}/ag-grid-theme/30-${theme}-condensed.min.css`,
  };

  Object.entries(widgetUrls).forEach(([id, url]) => {
    appendUniqueLinkToDocument(id, url);
  });
}

function getTheme(): Theme {
  // Get the theme from the SGWTWidgetConfiguration.context, or take the "standard" one as default.
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration.context
    ? (widgetConfiguration.context.getSgDesignSystemTheme() ?? 'standard')
    : 'standard';
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
  const newLinkElement = document.createElement('link');
  newLinkElement.rel = 'stylesheet';
  newLinkElement.id = id;
  newLinkElement.href = url;
  if (linkElement !== null) {
    newLinkElement.onload = () => {
      document.head.removeChild(linkElement);
    };
  }
  document.head.prepend(newLinkElement);
}
