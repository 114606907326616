import type { Dispatchable, Thunk } from '@/bootstrap/thunks.ts';
import type { ChainOptions } from '@/neos/business/neosActions.ts';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { valueBy } from '@/util/array/arrayUtils.ts';
import { flatMap, uniq, values } from 'lodash';

export function createFinalizeRfqsMissingUnderlyingsThunk(
  rfqIds: string[],
  shouldDisplayTab: boolean,
  otherUnderlyingIdsToRetrieve: string[] = [],
): Thunk {
  return function finalizeRfqsMissingUnderlyingsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          createUnderlyingInfoRequestedAction,
          createPartialUnderlyingInfoRequestedAction,
          createRequestSimilarActivitiesAction,
          createDefaultRfqRequestedAction,
        },
      },
      thunks,
    },
  ) {
    function getChainOptions(rfqIds: string[]): ChainOptions {
      return {
        success: {
          dispatchables: flatMap(rfqIds, rfqId => {
            const dispatchables: Dispatchable[] = [
              thunks.neos.createKeepSavedGlobalDeltaThunk(rfqId),
            ];

            if (shouldDisplayTab) {
              dispatchables.push(createRequestSimilarActivitiesAction(rfqId));
            }

            dispatchables.push(
              createDefaultRfqRequestedAction(
                rfqId,
                new DefaultingOptions({
                  enrichScopes: [DefaultingScope.TRADED_AWAY_FEEDBACK],
                }),
              ),
            );
            return dispatchables;
          }),
        },
      };
    }

    const appState = getState();

    const elsBasketRfqIds = rfqIds.filter(rfqId =>
      selectors.isRfqElsBasket(appState, rfqId, selectors),
    );

    const basketMissingUdlsByRfqId = valueBy(elsBasketRfqIds, rfqId =>
      selectors.getUnderlyingsIdsWithoutAnyInfo(appState, rfqId, selectors),
    );
    const allBasketRfqMissingUnderlyingIds = flatMap(basketMissingUdlsByRfqId, underlyingIds => {
      return values(underlyingIds);
    });
    const basketRfqMissingUnderlyingIds = uniq(allBasketRfqMissingUnderlyingIds);

    if (basketRfqMissingUnderlyingIds.length > 0) {
      const chainOptions = getChainOptions(elsBasketRfqIds);
      const affectedBasketProductRfqIds = elsBasketRfqIds.filter(
        rfqId => basketMissingUdlsByRfqId[rfqId].length > 0,
      );

      if (affectedBasketProductRfqIds.length) {
        dispatch(
          createPartialUnderlyingInfoRequestedAction(
            affectedBasketProductRfqIds,
            basketRfqMissingUnderlyingIds,
            chainOptions,
          ),
        );
      }
    }

    const otherRfqIds = rfqIds.filter(
      rfqId => !selectors.isRfqElsBasket(appState, rfqId, selectors),
    );

    const otherMissingUdlsByRfqId = valueBy(otherRfqIds, rfqId =>
      selectors.getUnderlyingsIdsWithoutFullInfo(appState, rfqId, selectors),
    );
    const otherRfqMissingUnderlyingIds = flatMap(otherMissingUdlsByRfqId, underlyingIds => {
      return values(underlyingIds);
    });
    const allMissingUnderlyingIds = otherRfqMissingUnderlyingIds.concat(
      otherUnderlyingIdsToRetrieve,
    );

    const missingUnderlyingIds = uniq(allMissingUnderlyingIds);

    if (missingUnderlyingIds.length > 0) {
      const chainOptions = getChainOptions(otherRfqIds);

      const affectedRfqIds =
        otherUnderlyingIdsToRetrieve.length > 0
          ? rfqIds
          : otherRfqIds.filter(rfqId => otherMissingUdlsByRfqId[rfqId].length > 0);

      if (affectedRfqIds.length) {
        dispatch(
          createUnderlyingInfoRequestedAction(affectedRfqIds, missingUnderlyingIds, chainOptions),
        );
      }
    }

    if (missingUnderlyingIds.length === 0 && basketRfqMissingUnderlyingIds.length === 0) {
      const chainOptions = getChainOptions(rfqIds);
      const dispatchables = chainOptions?.success?.dispatchables ?? [];
      dispatchables.forEach(toBeDispatched => dispatch(toBeDispatched));
    }
  };
}
