import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';

const availableSettingTabs = [
  'General',
  'RFQ',
  'Els Strategy',
  'Notifications',
  'Preconfirmations',
  'Counterparties',
  'Defaulting',
  'Brokers',
  'Default Trading Group',
] as const;
export type SettingTab = (typeof availableSettingTabs)[number];

export interface UserPreferencesModalModel {
  showModal: boolean;
  isDefaultingFeatureToggled: boolean;
  isPreconfToggled: boolean;
  isCounterpartiesFeatureToggled: boolean;
  isBrokersTabDisplayed: boolean;
  isDefaultTradingGroupTabDisplayed: boolean;
  tabNames: SettingTab[];
}

export function getUserPreferencesModel(
  state: AppState,
  selectors: Selectors,
): UserPreferencesModalModel {
  const userPreferencesState = state.ui.userPreferences;
  const isPreconfToggled =
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmail.enabled') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailFuture.enable') ||
    selectors.isFeatureToggleEnabled(state, 'neos.rfq.giveUpEmailDivFut.enable');
  const isCounterpartiesFeatureToggled = selectors.isFeatureToggleEnabled(
    state,
    'neos.user.preferences.counterparties',
  );
  const isDefaultingFeatureToggled = selectors.isFeatureToggleEnabled(
    state,
    'neos.user.preferences.defaulting',
  );
  const isBrokerFeatureToggled = selectors.isFeatureToggleEnabled(
    state,
    'neos.user.preferences.broker',
  );
  const isTrader = selectors.isTrader(state);

  const isBrokersTabDisplayed = isTrader && isBrokerFeatureToggled;
  const isDefaultTradingGroupTabDisplayed = !isTrader;

  const tabNames: SettingTab[] = availableSettingTabs.filter(tab => {
    if (tab === 'Preconfirmations') {
      return isPreconfToggled;
    }
    if (tab === 'Counterparties') {
      return isCounterpartiesFeatureToggled;
    }
    if (tab === 'Defaulting') {
      return isDefaultingFeatureToggled;
    }
    if (tab === 'Brokers') {
      return isBrokersTabDisplayed;
    }
    if (tab === 'Default Trading Group') {
      return isDefaultTradingGroupTabDisplayed;
    }
    return true;
  });

  return {
    isPreconfToggled,
    isCounterpartiesFeatureToggled,
    isDefaultingFeatureToggled,
    isBrokersTabDisplayed,
    isDefaultTradingGroupTabDisplayed,
    tabNames,
    showModal: selectors.isUserPreferencesModalShown(userPreferencesState),
  };
}
