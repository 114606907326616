import type { SalesGroup } from '@/neos/business/referenceData/salesGroups/salesGroupsModel';
import type { TraderGroup } from '@/neos/business/referenceData/traderGroups/traderGroupsModel';
import type { PartialResetType } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import type { SwapsWireSgTraderAccountType } from '@/neos/business/rfq/strategy/strategyData/strategyDataModel';

export interface CurrentUserPreferencesState {
  preferences: CurrentUserPreferences;
  referential: {
    cities: UserPreferencesCity[];
    countries: UserPreferencesCountry[];
    desks: SalesGroup[] | undefined;
    tradergroups: TraderGroup[] | undefined;
  };
}

export interface UserPreferencesCity {
  name: string;
  cityId: number;
}

export interface UserPreferencesCountry {
  countryRefId: number;
  refId: string;
  name: string;
}

export interface UserPreferencesStrategyTraderGroup {
  strategyId: string;
  traderGroupId: number | undefined;
}

export type CurrentUserPreferences = {
  defaultDeskId: string | undefined;
  swapswireAccountType: SwapsWireSgTraderAccountType | undefined;
  swapswireAccount: string | undefined;
  swapswireAccountingCenter: string | undefined;
  userEmail: string | undefined;
  city: UserPreferencesCity | undefined;
  country: UserPreferencesCountry | undefined;
  partialReset: PartialResetType | undefined;
  tradingBusiness: TradingBusinessType | undefined;
  strategyTraderGroup: UserPreferencesStrategyTraderGroup[] | undefined;
};

export const tradingBusinessTypes = {
  NONE: 'None',
  FLOW: 'Flow',
  FUNDING: 'Funding',
  POSITION: 'Position',
} as const;
export type TradingBusinessType = keyof typeof tradingBusinessTypes;

export const stockLoanTypes = {
  OPEN: 'Open',
  TERM: 'Term',
  DIV: 'Div',
  GTEED: 'Gteed',
  FUNDING: 'Funding',
  EXCLUSIVES: 'Exclusives',
  LONG_TERM_DIV: 'Long term div',
} as const;

export type StockLoanType = keyof typeof stockLoanTypes;
