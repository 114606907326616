import type { Selectors } from '@/bootstrap/selectors';
import type { AppState } from '@/bootstrap/state';
import {
  BookingStatusBorderColor,
  BookingStatusTooltip,
  type StatusColor,
} from '@/neos/business/neosOnyxModel';

export type SalesCreditStatusModel = {
  message: string | undefined;
  statusColor: StatusColor | undefined;
};

export function getSalesCreditStatusModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): SalesCreditStatusModel {
  const { uuid: strategyId } = selectors.getRfqMasterStrategy(state, rfqId);
  const isMasterStrategyOtc = selectors.isOtcStrategy(state, strategyId, selectors);

  const listedBookingStatus =
    selectors.getRfqAsiaSalesCreditBookingStep(state, rfqId, selectors)?.status ??
    selectors.getRfqEuropeSalesCreditBookingStep(state, rfqId, selectors)?.status;

  const bookingStatus = isMasterStrategyOtc
    ? selectors.getRFQAggregatedSalesCreditBookingStep(state, rfqId)
    : listedBookingStatus;

  if (!bookingStatus) {
    return {
      message: undefined,
      statusColor: undefined,
    };
  }

  return {
    message: BookingStatusTooltip[bookingStatus],
    statusColor: BookingStatusBorderColor[bookingStatus],
  };
}
