import type { Selectors } from '@/bootstrap/selectors';
import { services } from '@/bootstrap/services';
import type { AppState } from '@/bootstrap/state';
import type { PriceWithUnit } from '@/neos/business/rfq/rfqOnyxModel';
import { formatFromCamelToStartCase } from '@/util/format';
import { MarginRules } from '../../../../../neos/business/neosModel';
import { createBidAskGetter } from '../../utils/salesMargin/bidAskGetter';
import { getEffectiveWay } from '../../utils/salesMargin/getEffectiveWay';
import type { TotalSalesMarginModalPropsFromState } from './TotalSalesMarginModal';

export interface AvailableSalesMarginRuleModel {
  rule: MarginRules;
  label: string;
}

export interface TotalSalesMarginPropsFromState {
  isMarkupModalShown: boolean;
  availableSalesMarginRules: AvailableSalesMarginRuleModel[];
  selectedSalesCreditIncludedRule: MarginRules | undefined;
  selectedSalesMarginRule: MarginRules | undefined;
  salesMargins: SalesMargin[];
  displayedTotalSalesCredit: PriceWithUnit | undefined;
  isReadonlyRfq: boolean;
}

export interface TotalSalesMarginDispatchProps {
  onOpen: () => void;
}

export interface SalesMargin {
  totalAmount: number | undefined;
  includedAmount: number | undefined;
  marginAmount: number | undefined;
  unit: string | undefined;
  isReadOnly?: boolean;
}

export function getTotalSalesMarginModel(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): TotalSalesMarginPropsFromState {
  const { getSalesMarginRules, isMarkupModalShown } = selectors;
  const { getRfqData, getQuote, isReadOnlyRfq } = selectors;

  const { totalSalesMargin, totalSalesMarginEur, quoteId, clientWay, manualSalesCredit } =
    getRfqData(state, rfqId);
  const availableSalesMarginRules = getSalesMarginRules(state.referenceData).map(
    (rule): AvailableSalesMarginRuleModel => ({
      rule,
      label: rule === MarginRules.SPREAD_16 ? 'Spread 1/6' : formatFromCamelToStartCase(rule),
    }),
  );

  const {
    extraSalesMarginAsk,
    extraSalesMarginBid,
    includedSalesMarginAsk,
    includedSalesMarginBid,

    extraSalesMarginRule,
    includedSalesMarginRule,
  } = getQuote(state, quoteId);

  const effectiveWay = getEffectiveWay(clientWay, totalSalesMargin?.ask?.value);

  const bidAskGetter = createBidAskGetter(getEffectiveWay(clientWay, totalSalesMargin?.ask?.value));

  const salesMargins: SalesMargin[] = [
    {
      unit: 'EUR',
      totalAmount: bidAskGetter(totalSalesMarginEur, t => t?.value),
      marginAmount: undefined,
      includedAmount: undefined,
      isReadOnly: true,
    },
    {
      unit: bidAskGetter(totalSalesMargin, (t: PriceWithUnit | undefined) => t?.unit),
      totalAmount: bidAskGetter(totalSalesMargin, (t: PriceWithUnit | undefined) => t?.value),
      marginAmount: effectiveWay === 'BID' ? extraSalesMarginBid : extraSalesMarginAsk,
      includedAmount: effectiveWay === 'BID' ? includedSalesMarginBid : includedSalesMarginAsk,
      isReadOnly: false,
    },
  ];

  const firstSalesMargin = salesMargins.at(0);
  const totalSalesCredit: PriceWithUnit = {
    unit: firstSalesMargin?.unit,
    type: 'CCY',
    value: firstSalesMargin?.totalAmount,
  };

  return {
    displayedTotalSalesCredit: manualSalesCredit ?? totalSalesCredit,
    salesMargins,
    availableSalesMarginRules,
    selectedSalesCreditIncludedRule: includedSalesMarginRule,
    selectedSalesMarginRule: extraSalesMarginRule,
    isMarkupModalShown: isMarkupModalShown(state.ui, rfqId),
    isReadonlyRfq: isReadOnlyRfq(state, rfqId),
  };
}

export function getSalesCreditEliotId(
  state: AppState,
  rfqId: string,
  selectors: Selectors,
): Pick<TotalSalesMarginModalPropsFromState, 'eliotId'> {
  const rfqData = selectors.getRfqData(state, rfqId);
  const isStatusAfterOrEqualTraded = services.orderOf(rfqData.status).isAfterOrEqual('TRADED');
  const hasAtLeastOneListedStrategy = rfqData.strategyIds.some(strategyId =>
    selectors.isListedStrategy(state, strategyId, selectors),
  );
  const bookingStep = selectors.getRfqAsiaSalesCreditBookingStep(state, rfqId, selectors);

  const bookingStepStatus = bookingStep?.status;

  const isDisplayed =
    bookingStepStatus !== 'BOOKED' && hasAtLeastOneListedStrategy && isStatusAfterOrEqualTraded;
  return {
    eliotId: {
      isDisplayed,
      isReadOnly: false,
      value: rfqData.salesCreditBookingReference?.id ?? '',
    },
  };
}
