import type { Action } from '@/bootstrap/actions.ts';
import type { Thunk } from '@/bootstrap/thunks.ts';
import type { BreakFeePeriod } from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { assertProductIsEls } from '../../../../../strategyModel.ts';

export function createAddBreakFeePeriodThunk(strategyId: string): Thunk {
  return function addBreakFeePeriodThunk(
    dispatch,
    getState,
    {
      selectors,
      services,
      actionCreators: {
        neos: { productCrudActions },
      },
    },
  ) {
    const appState = getState();

    const product = selectors.getStrategyProduct(appState, strategyId, selectors);

    assertProductIsEls(product);

    const newPeriod: BreakFeePeriod = {
      id: services.idCreator.createId(),
      endDate: product.maturity ?? '',
      fee: undefined,
      nominalMax: product.dailyMaxSize,
      nominalMin: product.dailyMinSize,
      startDate: product.strikeDate ?? '',
      type: 'NONE',
    };

    const action: Action = productCrudActions.update(product.uuid, {
      breakFeePeriods: [...(product.breakFeePeriods ?? []), newPeriod],
    });

    dispatch(action);
  };
}
